import { Link } from "gatsby"
import React from "react"
import Button from "react-bootstrap/esm/Button"
import "./home.css"

const GrowYourBusiness = ({ data }) => (
  <div className="home-growyourbusiness-content">
    <div className="container">
      <div className="home-growyourbusiness">
        <div className="home-growyourbusiness-text">
          <h1 dangerouslySetInnerHTML={{ __html: data?.banner_title }}>
          </h1>
          <p dangerouslySetInnerHTML={{ __html: data?.banner_description }}>
          </p>
        </div>
        <div className="home-growbusiness-btn">
          <Link to={data?.banner_get_started?.link}>
            <Button>{data?.banner_get_started?.text}</Button>
          </Link>
        </div>
      </div>
    </div>
  </div>
)
export default GrowYourBusiness
