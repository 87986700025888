import React from "react"
import CaseStudyBanner from "../components/casestudy/casestudybanner"
import CaseStudyContent from "../components/casestudy/casestudycontent"
import GrowYourBusiness from "../components/Home/Growbusinesslight"
import Layout from "../components/Layout/layout"

const CaseStudyPage = () => (
  <Layout>
    <div className="case-study-page">
      <CaseStudyBanner />
      <CaseStudyContent />
      <GrowYourBusiness />
    </div>
  </Layout>
)
export default CaseStudyPage
