import React from "react"
import { useState } from "react"
import csimg from "../../images/casestudy/caseimg.svg"
import qoute from "../../images/casestudy/qoute.svg"
import more from "../../images/casestudy/moreimg.svg"

const CaseStudyContent = () => {
  const [active, setActive] = useState(0)
  const handleHeading = i => {
    if (typeof window !== "undefined") {
      let position = document.getElementById(i)?.getBoundingClientRect()
      window.scrollTo({
        left: position.left,
        top: position.top + window.scrollY - 10,
        behavior: "smooth",
      })
    }
    setActive(i)
  }

  return (
    <div className="case-study-content-container">
      <div className="container">
        <div className="case-study-content-content">
          <div className="case-study-content-left">
            <div className="cs-progress">
              <div className="progress-counter">
                <h1>1000+</h1>
                <span>Products sold</span>
              </div>
              <div className="progress-counter">
                <h1>10x</h1>
                <span>Return on investment</span>
              </div>
              <div className="progress-counter">
                <h1>37%</h1>
                <span>Email conversion rate</span>
              </div>
            </div>

            <div className="cs-img">
              <img src={csimg} alt="progress" />
            </div>

            <div className="cs-client-intro" id="client">
              <h2>Client Intro</h2>
              <p>
                A Beauty Products Ecommerce Store Facebook Ads Case Study. A
                beauty products E-com store in NYC needed Digital Marketing
                Services to increase Sales. Learn how we turned around things
                for them.
              </p>
            </div>

            <div className="cs-client-challenge" id="challenge">
              <h2>The Challenge</h2>
              <ul>
                <li>The client had been burned by another agency</li>
                <li>
                  We did not have any landing pages or offers to work with
                </li>
                <li>
                  The previous agency spent $10K on Facebook ads without any
                  real results
                </li>
                <li>
                  The pixel data was all messed up as the agency was using their
                  own pixel instead of using the client’s pixel
                </li>
                <li>
                  We had only 45 days to prove ourselves – the client was
                  cautious after having been burned in the past
                </li>
              </ul>
            </div>

            <div className="cs-our-strategy" id="strategy">
              <h2>our strategy</h2>
              <ul>
                <li>
                  Complete pixel installation – we couldn’t afford losing data
                </li>
                <li>
                  We worked with the client to come up with a Freemium Model
                </li>
                <li>We identified 2 products to test for the freemium model</li>
                <li>Created 4 different landing pages for Freemium strategy</li>
                <li>Email marketing strategy for follow up</li>
                <li>Retargeting funnel strategy</li>
                <li>
                  Audience strategy was based on competitors, interests, job
                  titles and Lookalikes.
                </li>
              </ul>
            </div>

            <div className="cs-result" id="result">
              <h2>The Result</h2>
              <ul>
                <li>The results skyrocketed in the very first month</li>
                <li>Free plus shipping cost per result was as low as $5.40</li>
                <li>
                  ROAS even for freemium products was in positive because of the
                  shipping charges
                </li>
                <li>900+ purchases recorded after the first month</li>
                <li>Cost per purchase for core products was below $8</li>
                <li>
                  ROAS for core products jumped to 10.64% just from Facebook ads
                </li>
                <li>
                  Email marketing conversion from a free product to core product
                  was 37%
                </li>
                <li>AOV was increased using bundle offers</li>
              </ul>
            </div>

            <div className="cs-quotation">
              <img src={qoute} alt="qoute" />
              <blockquote>
                Turpis et risus sapien, ut quis tortor tellus suspendisse
                scelerisque. Bibendum vulputate justo, nibh neque. Auctor
                viverra sagittis in faucibus ut sit viverra sapien.{" "}
              </blockquote>
            </div>
          </div>
          <div className="case-study-content-right">
            <div className="case-study-sticky">
              <div className="case-study-table-content">
                <h4>In this case study...</h4>
                <div className="case-study-table-content-post">
                  <ul>
                    <li
                      onClick={() => handleHeading("client")}
                      className={active === "client" ? "active" : ""}
                      role="presentation"
                    >
                      <p to="#client">Client Intro</p>
                    </li>
                    <li
                      onClick={() => handleHeading("challenge")}
                      className={active === "challenge" ? "active" : ""}
                      role="presentation"
                    >
                      <p to="#challenge">The Challenge</p>
                    </li>
                    <li
                      onClick={() => handleHeading("strategy")}
                      className={active === "strategy" ? "active" : ""}
                      role="presentation"
                    >
                      <p to="#strategy">our strategy</p>
                    </li>
                    <li
                      onClick={() => handleHeading("result")}
                      className={active === "result" ? "active" : ""}
                      role="presentation"
                    >
                      <p to="#result">The Result</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="cs-more">
                <h3>More Case Studies</h3>
                <div className="cs-more-items">
                  <div className="cs-more-box">
                    <img src={more} alt="more" />
                    <div class="case-info-more">
                      <span class="case-tag-more">Insurance Agency</span>
                      <span>2 min read</span>
                    </div>
                    <h4>
                      How to optimize your pages to get more traffic on mobile
                      devices
                    </h4>
                  </div>
                  <div className="cs-more-box">
                    <img src={more} alt="more" />
                    <div class="case-info-more">
                      <span class="case-tag-more">Ecommerce Business</span>
                      <span>2 min read</span>
                    </div>
                    <h4>
                      How to optimize your pages to get more traffic on mobile
                      devices
                    </h4>
                  </div>
                  <div className="cs-more-box">
                    <img src={more} alt="more" />
                    <div class="case-info-more">
                      <span class="case-tag-more">Life Coaching company</span>
                      <span>2 min read</span>
                    </div>
                    <h4>
                      How to optimize your pages to get more traffic on mobile
                      devices
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CaseStudyContent
