import React from "react"
import "./casestudy.css"

const CaseStudyBanner = () => (
  <div className="case-study-banner">
    <div className="container">
      <div className="case-study">
        <div class="case-info">
          <span class="case-tag">Beauty Store</span>
          <span>2 min read</span>
        </div>
        <h1>
          How our beauty store client went from under 30 clients to over 400
          clients in just 45 days
        </h1>
      </div>
    </div>
  </div>
)
export default CaseStudyBanner
